body {
  margin: 0;
  letter-spacing: 0.05em;
  font-family: "Outfit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

h1{
  font-weight: 700;
}
p{
  font-weight: 300;
}

@media (max-width: 800px) {
  p{
    font-size: 80%;
  }
}