.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 10%;
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.nav-links a {
    color: white;
    text-decoration: none;
    font-weight: 500;
}

.nav-links a:hover {
    color: #ff0202;
}

.nav-logo {
    height: 5vh;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: bold;
    overflow: hidden;
    position: relative;
}

/* Falling Animation */
.falling-text {
    display: inline-block;
    opacity: 0;
    animation: fallIn 2s ease forwards;
}

@keyframes fallIn {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width: 640px) {
    .navbar {
        justify-content: center;  /* Center align navbar content */
    }

    .nav-logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);  /* Center logo horizontally */
    }

    .nav-links {
        display: none;  /* Hide nav links */
    }
}