.footer {
    background-color: #333;
    color: #fff;
    padding: 40px 0;
    text-align: center;
    margin-top: auto;
}

.footer-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer-container div {
    flex: 1 1 200px;
    padding: 20px;
}

.footer h3 {
    margin-bottom: 20px;
}

.footer a {
    color: #fff;
    text-decoration: none;
}

.footer a:hover {
    color: #ED1C24;
}

.social-icons {
    margin-top: 10px;
}

.social-icons a {
    margin: 0 10px;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
}

.social-icons a:hover {
    color: #ED1C24;
}

.footer-bottom {
    margin-top: 20px;
}

