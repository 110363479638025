:root {
  --brown-university-brown: #4E3629;
  --text-black: #000000;
  --background-white: #ffffff;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  scroll-padding-top: 70px;
  overflow-y: auto;
  scroll-behavior: smooth;
  height: 100%;
}

.separator {
  border: 0;
  height: 5px;
  background: #0c0000;
  margin: 0;
  padding: 0;
}

section {
  padding: 1vh;
  flex: 1;
}

.section-intro {
  background-color: var(--brown-university-brown);
  display: flex;
  align-items: center;
  background-image: url('brown.png');
  background-repeat: repeat;
  background-size: 150px 150px;
  background-position: center;
  height: 110vh;
  flex-direction: column;
}

.section-skills {
  background-color: var(--background-white);
  color: var(--text-black);
  height: 100vh;
}

.section-projects {
  background-color: var(--brown-university-brown);
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: auto;
}

.section-experiences {
  background-color: var(--background-white);
  color: var(--text-black);
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: auto;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container h1 {
  padding-top: 7vh;
  padding-bottom: 5vh;
}


@media (max-width: 1100px) {
  section {
    box-sizing: border-box;
  }

  .container h1 {
    padding: 7vh;
  }
}

@media (max-width: 640px) {
  .container h1 {
    padding: 3vh 0;
  }
}

@media (max-height: 1500px) {
  .section-intro {
    height: 70vh;
  }
}

@media (max-height: 1300px) {
  .section-intro {
    height: 80vh;
  }
}
@media (max-height: 1100px) {
  .section-intro {
    height: 100vh;
  }
  .section-projects{
    min-height: 100vh;
  }
  .section-experiences{
    min-height: 100vh;
  }
}
@media (max-height: 800px) {
  .section-intro {
    height: 120vh;
  }
  .section-projects{
    min-height: 120vh;
  }
  .section-experiences{
    min-height: 120vh;
  }
}

[ext-id],
[shadow-root] {
  display: none !important;
  position: fixed !important;
  /* Prevent layout disruption */
  bottom: 0;
  z-index: 9999;
}

/* Style the section */
.section-projects {
  background-color: var(--brown-university-brown);
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  text-align: center;
}

/* Container styling */
.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Style the view toggle buttons */
.view-toggle-projects {
  margin: 1.5rem 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.view-toggle-projects button {
  background: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.view-toggle-projects button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.view-toggle-projects button.active {
  background: #ffffff;
  color: var(--brown-university-brown);
}
/* Style the view toggle buttons */
.view-toggle-experiences {
  margin: 1.5rem 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.view-toggle-experiences button {
  background: #ffffff;
  border: 2px solid var(--brown-university-brown);
  color: var(--brown-university-brown);
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.view-toggle-experiences button:hover {
  background: rgba(0, 0, 0, 0.2);
}

.view-toggle-experiences button.active {
  background: var(--brown-university-brown);
  color: #ffffff;
}

/* Make sure grid and slideshow have spacing */
.project-grid,
.slideshow-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

