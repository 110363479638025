/* grid.css */
.project-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* Responsive grid */
    gap: 1.5rem;
}

.project-card {
    background-color: #1c1c1c; /* Dark background */
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.3); /* White border with slight transparency */
}

.project-card:hover {
    transform: translateY(-5px);
    border-color: rgba(255, 255, 255, 0.6); /* Make the border more visible on hover */
}

.project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    background-color: #000;
}

.project-details {
    padding: 1rem;
    color: #fff;
}

.project-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.project-role {
    font-size: 0.9rem;
    color: #aaa;
    margin-bottom: 0.5rem;
}

.project-description {
    font-size: 0.95rem;
    color: #ddd;
    line-height: 1.4;
}

.slide-buttons {
    display: flex;
    margin-top: 20px;
}

.project-button {
    background-color: var(--primary-color, #ED1C24);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 5px;
    transition: background-color 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.project-button.github-exists{
    margin-right: 10px;
}
.project-button:hover {
    background-color: #c51b21;
}

.github-button {
    background-color: #676767;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.github-button:hover {
    background-color: #444;
}