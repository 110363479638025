.tech-stack-row {
    display: flex;
    align-items: baseline;
    justify-content: center;  /* This centers the items horizontally */
    gap: 12px;
    margin-top: 10px;
    flex-wrap: wrap;
}

.tech-icon-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tech-icon:hover {
    transform: scale(1.1);
}

/* Tooltip */
.tech-icon-container::before {
    content: attr(data-tooltip);
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    position: absolute;
    bottom: 125%;
    /* Show tooltip above */
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s;
}

.tech-icon-container:hover::before {
    visibility: visible;
    opacity: 1;
}

.slideshow-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 900px;
    margin: 0 auto;
}

.carousel {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    height: auto;
}

.carousel-root {
    width: 100%;
}

.carousel-slide {
    width: 100%;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slide-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.slide-content img {
    width: 40%;
    max-width: 500px;
    height: auto;
    border-radius: 1vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.slide-metadata {
    flex: 1;
    margin-left: 30px;
    max-width: 400px;
}

/* Tech Stack Row */
.tech-stack-row {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.carousel .slide .tech-icon {
    width: 3vh;
    height: 3vh;
    object-fit: contain;
    transition: transform 0.3s ease, filter 0.3s ease;
}

.tech-stack-row p {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
}

/* Slide Buttons */
.slide-buttons {
    display: flex;
    margin-top: 20px;
}

.slideshow-button {
    background-color: var(--primary-color, #ED1C24);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 5px;
    transition: background-color 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.slideshow-button.github-exists{
    margin-right: 10px;
}
.slideshow-button:hover {
    background-color: #c51b21;
}

.github-button {
    background-color: #676767;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.github-button:hover {
    background-color: #444;
}

/* Arrows */
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    opacity: 1;
}

/* Dots (Indicators) */
.carousel .control-dots {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
}

.carousel .dot {
    height: 12px;
    width: 12px;
    margin: 0 5px;
    background-color: rgb(204, 117, 117) !important;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.carousel .dot:hover {
    background-color: #ED1C24 !important;
}

.carousel .dot.selected {
    background-color: #ac171c !important;
}

/* Responsive Adjustments */
@media (max-width: 1000px) {
    .slide-content {
        justify-content: center;
        margin: 0 auto;
    }
    .slide-buttons {
        justify-content: center;
    }
    .slide-buttons.github-exists {
        padding-left: 5%;
    }
    .slideshow-button {
        font-size: 9px;
    }
}

/* Responsive Layout for Tablets */
@media (max-width: 1000px) and (min-width: 641px) {
    .slide-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 600px;
    }
    .carousel .control-dots {
        display: none;
    }
    .slide-content img {
        width: 70%;
        margin-bottom: 20px;
    }

    .slide-metadata {
        margin-left: 0;
        max-width: 500px;
    }

    .tech-stack-row {
        gap: 15px;
    }
}

/* Responsive Layout for Mobile Screens */
@media (max-width: 640px) {
    .slide-content {
        max-width: 300px;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 0 auto;
    }
    .carousel .control-dots {
        display: none;
    }
    .slide-content img {
        margin-bottom: 20px;
    }

    .slide-metadata {
        margin-left: 0;
        max-width: 400px;
    }

    .tech-stack-row {
        justify-content: flex;
        /* Align to the left */
        align-items: baseline;
        /* Align text with icons */
        flex-wrap: wrap;
        gap: 8px;
    }

    .tech-stack-row p {
        font-size: 14px;
    }
}

/* Adjust slide height for shorter screens */
@media (max-height: 700px) {
    .carousel-slide {
        min-height: 600px;
    }
}

/* Hide dots on small screens */
@media (max-width: 768px) {
    .carousel .control-dots {
        display: none;
    }

    .carousel .control-arrow {
        display: flex !important;
    }
}

/* Custom Arrows (Default for Small Screens) */
.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background: rgb(133, 133, 133);
    color: white;
    border: none;
    padding: 8px;
    width: 4vh;  /* Set equal width */
    height: 4vh; /* Set equal height */
    border-radius: 50%;  /* Perfect circle */
    font-size: 1.2rem;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

.custom-arrow:hover {
    background: rgba(0, 0, 0, 0.8);
}

.custom-arrow-left {
    left: 5px;
}

.custom-arrow-right {
    right: 5px;
}

.custom-arrow.disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

/* Hide arrows on large screens */
@media (min-width: 1000px) {
    .custom-arrow {
        display: none;
    }
}

/* Smaller Arrows for Mobile Screens */
@media (max-width: 768px) {
    .custom-arrow {
        font-size: 1rem;
        padding: 5px;
    }

    .custom-arrow-left {
        left: 2px;
    }

    .custom-arrow-right {
        right: 2px;
    }
}

/* Extra Small Screens */
@media (max-width: 480px) {
    .custom-arrow {
        font-size: 0.9rem;
        padding: 3px;
    }
}