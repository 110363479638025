:root {
    --brown-university-red: #ED1C24;
}

/* Flip Card Container */
.flip-card {
    display: inline-block;
    width: 20%;
    aspect-ratio: 3 / 4;
    margin-top: 10vh;
    margin-bottom: 5vh;
    perspective: 1200px;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.5s ease;
    background-color: transparent;
    cursor: pointer;
}

.flip-card:hover {
    transform: translateY(-15px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.7);
}

/* Inner Flip Card Animation */
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s ease;
    transform-style: preserve-3d;
}

.flip-card.flipped .flip-card-inner {
    transform: rotateY(180deg);
}

/* Front and Back of Flip Card */
.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 15px;
}

.flip-card-back {
    background-color: #333;
    color: white;
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

/* Headshot Styling */
.headshot {
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

/* Click Me Text */
.click-me-text {
    position: absolute;
    bottom: 5px;
    left: 30%;
    width: 40%;
    text-align: center;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
    background: rgba(0, 0, 0, 0.4);
    padding: 8px 0;
    border-radius: 15px;
    cursor: pointer;
    animation: fadeIn 0.6s ease forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Bio Container */
.bio-container {
    width: 50%;
    text-align: center;
    color: #fff;
    margin: 0 auto;
}

/* Typewriter Effect for Title */
.typewriter {
    display: inline-block;
    overflow: hidden;
    border-right: 2px solid white;
    white-space: nowrap;
    width: 0;
    animation: typing 1s steps(13, end) forwards, blink 0.75s step-end infinite;
}

.placeholder {
    visibility: hidden;
    white-space: nowrap;
    display: inline-block;
}

/* Typing Animation */
@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}

/* Resume Button */
.resume button {
    padding: 0.8em 1.8em;
    border: 2px solid var(--brown-university-red);
    background-color: transparent;
    text-transform: uppercase;
    font-size: 16px;
    position: relative;
    overflow: hidden;
    transition: 0.3s ease;
    z-index: 1;
    font-family: inherit;
    color: var(--brown-university-red);
}

.resume button::before {
    content: '';
    width: 0;
    height: 300%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: var(--brown-university-red);
    transition: 0.5s ease;
    display: block;
    z-index: -1;
}

.resume button:hover::before {
    width: 105%;
}

.resume button:hover {
    color: #111;
}

/* Hover Text for Centering */
.hover-text {
    margin: 0 auto;
    display: block;
    text-align: center;
    width: fit-content;
}

/* Bio Text Hover Effect */
.word {
    display: inline-flex;
    flex-wrap: wrap;
}

.hover-text-bio {
    display: inline-block;
    transition: transform 0.3s ease, color 0.3s ease;
}

.hover-text-bio:hover {
    color: var(--brown-university-red);
    transform: translateY(-2px);
    cursor: pointer;
}

/* Responsive Adjustments */
@media (max-width: 800px) {
    .flip-card {
        width: 50%;
    }
    .bio-container {
        width: 80%;
    }
    .resume button {
        font-size: 13px;
    }
}

@media (max-width: 640px) {
    .click-me-text {
        font-size: small;
    }
    .bio-container {
        width: 90%;
    }
}
